import { default as _91_46_46_46slug_93M9ovAAzForMeta } from "/workspace/kody/hi3/pages/[...slug].vue?macro=true";
import { default as charsWsIFCZQPtMMeta } from "/workspace/kody/hi3/pages/chars.vue?macro=true";
import { default as index1MJYwgPkznMeta } from "/workspace/kody/hi3/pages/index.vue?macro=true";
import { default as launcherDQqpji3qLjMeta } from "/workspace/kody/hi3/pages/launcher.vue?macro=true";
import { default as component_45stubaq4ZUMgL7MMeta } from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubaq4ZUMgL7M } from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/workspace/kody/hi3/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "chars",
    path: "/chars",
    component: () => import("/workspace/kody/hi3/pages/chars.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/workspace/kody/hi3/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "launcher",
    path: "/launcher",
    component: () => import("/workspace/kody/hi3/pages/launcher.vue").then(m => m.default || m)
  },
  {
    name: component_45stubaq4ZUMgL7MMeta?.name,
    path: "/valks",
    component: component_45stubaq4ZUMgL7M
  }
]