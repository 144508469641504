import revive_payload_client_flip9Yri39 from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_M6eIcfhcya from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_goq39IIipq from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_c8Jr63pVD4 from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_xRqCmTBOBK from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_e09IxKUAc5 from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_E3G3gCLtnn from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_AWqohYAA9g from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/workspace/kody/hi3/.nuxt/components.plugin.mjs";
import prefetch_client_JRlD8DPbj2 from "/workspace/kody/hi3/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.5.5_eslint@9.10.0_jiti@1.21.6__ioredis@5.4.1_5qu7tqcwrpqqhsi6fqlsbg5xsy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
export default [
  revive_payload_client_flip9Yri39,
  unhead_M6eIcfhcya,
  router_goq39IIipq,
  payload_client_c8Jr63pVD4,
  navigation_repaint_client_xRqCmTBOBK,
  check_outdated_build_client_e09IxKUAc5,
  view_transitions_client_E3G3gCLtnn,
  chunk_reload_client_AWqohYAA9g,
  components_plugin_KR1HBZs4kY,
  prefetch_client_JRlD8DPbj2
]